import React from "react";

const FacebookIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="6.574" height="14.166" viewBox="0 0 6.574 14.166">
    <path
      id="Path_675"
      data-name="Path 675"
      d="M201.008,217.719h-2.939v-7.07h-1.035c-.363,0-.363,0-.363-.366v-2.14h1.4c0-.077,0-.139,0-.2.008-.6-.017-1.2.032-1.8a2.577,2.577,0,0,1,2.439-2.548c.874-.07,1.758-.026,2.637-.033,0,0,.009.005.024.015v2.414h-.167c-.454,0-.907,0-1.361,0a.567.567,0,0,0-.649.572c-.028.517-.007,1.036-.007,1.57h2.228c-.013.159-.023.3-.037.443-.065.651-.134,1.3-.2,1.953-.011.114-.072.124-.163.123-.545,0-1.091,0-1.636,0h-.205Z"
      transform="translate(-196.672 -203.553)"
      fill="#fff"
    />
  </svg>
);

export default FacebookIcon;
