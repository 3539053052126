import React from "react";

const TwitterIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="14.655" height="11.726" viewBox="0 0 14.655 11.726">
    <path
      id="Path_676"
      data-name="Path 676"
      d="M111.4,279.778h-1.531c-.418-.072-.84-.129-1.254-.221A8.786,8.786,0,0,1,106,278.481v-.038a5.944,5.944,0,0,0,4.346-1.18,3.251,3.251,0,0,1-1.662-.628,2.856,2.856,0,0,1-1.036-1.435,4.335,4.335,0,0,0,1.2-.076,3.221,3.221,0,0,1-1.622-1.072,2.953,2.953,0,0,1-.644-1.864,2.924,2.924,0,0,0,1.234.317,3.135,3.135,0,0,1-1.136-1.784,2.736,2.736,0,0,1,.373-2.12c.066.049.091.129.143.181a13.987,13.987,0,0,0,1.014.98,8.387,8.387,0,0,0,4.695,1.9c.282.023.289.024.254-.261a2.867,2.867,0,0,1,.837-2.457,3.056,3.056,0,0,1,4.223-.081.294.294,0,0,0,.327.084l1.485-.548c.055-.021.108-.048.162-.073l.036.042a3.111,3.111,0,0,1-1.155,1.5,5.277,5.277,0,0,0,1.585-.424v.076a.576.576,0,0,0-.084.076,6.625,6.625,0,0,1-1.292,1.316.4.4,0,0,0-.108.263c-.023.322-.01.648-.043.969a8.513,8.513,0,0,1-2.237,4.991,8.077,8.077,0,0,1-5.006,2.575C111.721,279.729,111.558,279.754,111.4,279.778Z"
      transform="translate(-106 -268.052)"
      fill="#fff"
    />
  </svg>
);

export default TwitterIcon;
