import React from 'react';

const LocationIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="7.454" height="10" viewBox="0 0 7.454 10">
    <path
      id="Path_652"
      data-name="Path 652"
      d="M235.11,340.85c-.655-.886-1.3-1.739-1.921-2.6a18.066,18.066,0,0,1-1.358-1.957,3.685,3.685,0,0,1,2.733-5.4,3.766,3.766,0,0,1,4.256,3.3,3.7,3.7,0,0,1-.689,2.539C237.154,338.11,236.138,339.455,235.11,340.85Zm0-4.342a1.927,1.927,0,0,0,1.949-1.933,1.947,1.947,0,0,0-3.893-.008A1.932,1.932,0,0,0,235.112,336.509Z"
      transform="translate(-231.382 -330.85)"
      fill="#fff"
    />
  </svg>
);

export default LocationIcon;
