import ILocale from "./locale"

const en: ILocale = {
  nav_HelpUsKeepDataReliable: "Help us keep data reliable! Report Errors, New Info and Verify Data",
  "nav_covid19": "COVID-19",
  "nav_Nepal": "Nepal",
  "nav_NEP": "NEP",
  "nav_ENG": "ENG",
  "nav_Home": "Home",
  "nav_Symptoms": "Symptoms",
  "nav_JoinUs": "Join Us",
  "nav_GovNotice": "Notices",
  "nav_News": "News",
  "nav_Cases": "Cases",
  "nav_Emergency": "Emergency",

  "com_EmergencyContact": "Emergency Contact",
  "com_HospitalContact": "Hospital Contact",
  "com_noRecordsFound": "No records found",

  "covC_Update": "Update",
  "covC_Updated": "Updated",
  "covC_ago": "ago",
  "covC_Global": "Global",
  "covC_nepalGovReportNo": "Nepal Govt. reported numbers",
  "covC_totalTested": "Total Tested",
  "covC_totalConfirmed": "Total Confirmed",
  "covC_totalRecovered": "Total Recovered",
  "covC_totalDeath": "Total Deaths",
  "covC_whoReportedNo": "WHO reported numbers",

  "contac_whereToGetTested": "Where to get tested?",
  "contac_whenToGetTested": "When to get tested?",
  "contac_hospitalWhereToGetTested": "Hospitals where you may get tested for Covid-19",
  "contac_hospitalName": "Hospital Name",
  "contac_openHours": "Open Hours",
  "contac_phoneNumber": "Phone Number",
  "contac_exposedToDevelopSymptoms": "If you believe you have been exposed to COVID-19 and develop symptoms like fever, cough and difficulty of breathing, call your doctor!",
  "contac_highFever": "High Fever",
  "contac_drycough": "Dry Cough",
  "contac_difficultToBreathing": "Difficulty in breathing",
  "contac_hospitalCapacityData": "Hospital Capacity Data",

  "covC_disclaimerNepalGovJohnsHopkins": "*Disclaimer: These numbers are obtained from <1>Nepal Government </1> and <3>Johns Hopkins University</3> and being updated as the numbers from these sources get updated.",
  "contac_disclaimerHospitalGoverWebsite": "*Disclaimer: We are sourcing this data from various sources such as government website, situation report, hospital operators, doctors, etc. We need your help to continue gathering this information across all  hospitals in Nepal. If you would like to help collect data, please sign up <1> here </1>.",

  "fil_Province": "Province",
  "fil_District": "District",

  "hosp_Address": "Address",
  "hosp_OpenHours": "Open Hours",
  "hosp_Contact": "Contact",
  "hosp_TotalBeds": "Total Beds",
  "hosp_ICUBeds": "ICU Beds",
  "hosp_Ventilators": "Ventilators",
  "hosp_IsolationsBeds": "Isolation Beds",

  "emerg_sukrarajTropicalInfecDisHosp": "Sukraraj Tropical And Infectious Disease Hospital",
  "emerg_asProvideGovNotice": "As provided in the government notices",
  "emerg_nightOnly": "Nights only",

  "emerg_drShrawanKMishra": "Dr. Shrawan K. Mishra",
  "emerg_drRanjitShah": "Dr. Ranjit Shah",
  "emerg_mrRajeshKGupta": "Mr. Rajesh K. Gupta",
  "emerg_mrDineshThapaMagar": "Mr. Dinesh Thapa Magar",
  "emerg_mrNareshThapaMagar": "Mr. Naresh Thapa Magar",

  "sym_CONTAGION": "CONTAGION",
  "sym_HumanContact": "Human Contact",
  "sym_ContaminatedObject": "Contaminated Objects",
  "sym_ShortnessOfBreath": "Shortness of Breath",
  "sym_AcheNPain": "Aches & Pain",
  "sym_Fatigue": "Fatigue",
  "sym_Fever": "Fever",
  "sym_DryCough": "Dry Cough",
  "sym_RunnyNose": "Runny Nose",
  "sym_SoreThroat": "Sore Throat",
  "sym_Diarrohea": "Diarrhoea",
  "sym_Preventions": "PREVENTIONS",
  "sym_WearMasks": "Wear Masks",
  "sym_WashYourHands": "Wash your Hands",
  "sym_SocialDistancing": "Social Distancing",
  "sym_SometimeChildren": "Sometimes for children",
  "sym_sourcesFooter": "Sources: CDC, WHO, American College of Allergy, Asthma and Immunology",

  "nav_FAQ": "FAQs",
  "sym_Headache": "Headache",
  "symCh_commonCold": "Common Cold",
  "symCh_Flu": "Flu",
  "symCh_Allergies": "Allergies",
  "symCh_Sneezing": "Sneezing",
  "symCh_Common": "Common",
  "symCh_Sometimes": "Sometimes",
  "symCh_Rare": "Rare",
  "symCh_No": "No",
  "symCh_Mild": "Mild",

  "join_openSource": "Open Source Platform for COVID-19 for Nepal",
  "join_askingAllLikeMinded": "is an Open Source Platform started at <1>Fusemachines</1> and asking all like minded citizens of Nepal to join in this effort to create an Open Source platform to serve as a reliable source of information about Covid-19 in Nepal.<3></3>Let’s fight this pandemic together!",
  "join_objective": "Objective",
  "join_objectiveDetail_1": "Collect reliable data from various trusted sources, so public can stay well informed and leverage that data to better manage their fight with this pandemic.",
  "join_objectiveDetail_2": "Make relevant data easily consumable to get right information quickly, so informed decisions can be made during time of panic, should there be one.",
  "join_objectiveDetail_3": "In future, this could be extended to a public sourced and curated portal for additional real-time information on the outbreak.",
  "join_objectiveDetail_4": "In future, AI capabilities could be built to provide early warning projections.",

  "join_process": "Our process for collecting data:",
  "join_processDetail_1": "Government released data from various government and IT agencies websites and published documents.",
  "join_processDetail_2": "World Health Organization (WHO) published data.",
  "join_processDetail_3": "Our data team reaching out to hospitals and health clinics to collect data.",
  "join_processDetail_4": "Our data team Reaching out to pool of doctors to help with hospital capacity data.",
  "join_processDetail_5": "Reaching out to general public to help with data collection.",

  "join_hereHowCanHelpUs": "Here is how you can help us",
  "join_accessThisForm": "Access this form",
  "join_reliableInfo": "If you have reliable information that can help general public then please input",
  "join_reachOut": "Reach out to your contact that may have reliable information and ask them for help.",
  "join_contribute": "If you would like to contribute to this open source project as an engineer, data analyst, doctor, nurse, government officials, media or any other profession please join this effort by emailing how you want to contribute to the following email address",

  "notic_governNotice": "Government Notices",
  "notic_resources": "Resources",
  "notic_support": "Other Covid-19 Supports",
  "notic_reports": "Reports",
  "notic_pressRelease": "Press Release",
  "notic_pressRelease21March": "Press Release as of 21 March 2020",
  "notic_situationReport20March": "Situation Report_COVID-19 #42 (as of 20 March 2020)",
  "notic_situationReport1To41": "Situation Reports #1 to #41",
  "notic_travelRestriction": "Updated notice on travel restriction related to COVID-19",

  "resourc_howToProtectUrself": "How to protect yourself and others against COVID-19",
  "resourc_joinFight": "Join the fight against misinformation",

  faq_generatFAQ: 'General FAQs',

  "faq_q1_ques": "Is Teku-based hospital, Sukraraj Tropical and Infectious Disease Hospital, the only hospital allocated for testing and treatment of COVID-19 in Nepal?",
  "faq_q1_ans_1": "No. The Government of Nepal has allocated two hospitals, STIDH(Teku) hospital, and Patan Hospital, where you can get tested for Covid-19 infection. Teku hospital is, however, given the first priority since it specializes in dealing with all kinds of infectious diseases like COVID-19.  They work closely with the National Public Health Laboratory which is also situated in Teku that performs the confirmatory testing for COVID-19. Do remember that you do not have to go to Teku hospital to take the swab specimen as other hospitals can take a specimen and send it to NPHL. For the treatment of COVID-19, all hospitals can manage beds, ICUs and ventilators for severely infected patients. Since there is no evidence that current medicine can prevent or cure the disease, each case can be managed by all the hospitals, giving antiviral and antibacterial therapy.",
  "faq_q1_ans_2": "Yes, Teku hospital is a specialized hospital that has more experience dealing with the case of viral diseases like this. They are also close to National Lab which is the only lab in Nepal (as of today) that does confirmatory testing for COVID-19. But please remember other hospitals can take a specimen and send it to NPHL. Patients do not have to go to Teku hospital for it.",
  "faq_q2_ques": "With how many testing kits is the Teku hospital equipped currently?",
  "faq_q2_ans": "The last news suggests that the Teku hospital is equipped with about 1500 test kits. 522 tests were performed until yesterday.",
  "faq_q3_ques": "Is the swab testing carried out in Nepal or sent abroad? What is the medium of transportation of the specimens?",

  "faq_q3_ans": "Testing is done in Nepal; however, sometimes sample of positive cases are sent to a WHO reference lab in the region for re-confirmation. The doctors are, however, immediately notified when a case shows a positive result. It is transported in the Viral Transport Medium (VTM). You can uderstand it as a tube with rubber seals and has chemicals inside it to prevent the specimen from disintegrating. It is generally Tripple packed in virus transport package. Maintenance should be in a cold chain of 2-8 degrees. Probably put in a puncture-resistant box and sent in a plane.",
  "faq_q4_ques": "If the test sample is transported to the laboratory outside Nepal,  how long does it take for the reports to arrive in Nepal?",
  "faq_q4_ans": "It depends on cases but it is not very quick. It generally takes two hours or so to perform the test and can generally take a week to get the final result. The positive cases are reported to doctors immediately so that you are immediately transferred to the isolation room and treated accordingly.",
  "faq_q5_ques": "What is the survival rate & time period of the virus outside the human body?",
  "faq_q5_ans": "No one knows for sure. It depends on the environmental condition, but studies suggest it can survive for thousands of years in the right scenario. Since they are not considering living and do not have any metabolism they are either inactive or active. Not live or dead."
}

export default en
