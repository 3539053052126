import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import lo from 'src/i18n/en';

const SymtomSVG: FC = () => {
  const [t] = useTranslation();

  return (
    <div className="symptoms">
      <svg
        xmlns="https://www.w3.org/2000/svg"
        width="400"
        height="310.715"
        viewBox="0 0 400 310.715"
        className="img-fluid"
      >
        <defs>
          <clipPath id="clip-path">
            <circle id="Ellipse_17" data-name="Ellipse 17" cx="88.916" cy="88.916" r="88.916" fill="none" />
          </clipPath>
        </defs>
        <g id="symptoms" transform="translate(-396 -212.111)">
          <g id="Group_217" data-name="Group 217" transform="translate(445.334 212.111)">
            <path
              id="Path_655"
              data-name="Path 655"
              d="M154.926,0A154.926,154.926,0,1,1,0,154.926,154.926,154.926,0,0,1,154.926,0Z"
              transform="translate(0 0)"
              fill="#004e90"
              opacity="0.2"
            />
            <circle
              id="Ellipse_11"
              data-name="Ellipse 11"
              cx="122.963"
              cy="122.963"
              r="122.963"
              transform="translate(31.963 31.963)"
              fill="#004e90"
              opacity="0.2"
            />
            <g id="Group_216" data-name="Group 216" transform="translate(66.298 44.393)">
              <circle
                id="Ellipse_12"
                data-name="Ellipse 12"
                cx="88.916"
                cy="88.916"
                r="88.916"
                transform="translate(0 21.492)"
                fill="#003f7d"
              />
              <g id="Group_211" data-name="Group 211" transform="translate(0 21.492)">
                <g id="Group_210" data-name="Group 210" clipPath="url(#clip-path)">
                  <g id="Group_209" data-name="Group 209" transform="translate(-11.415 -21.492)">
                    <path
                      id="Path_200"
                      data-name="Path 200"
                      d="M237.255,344.6a.44.44,0,0,1-.274-.094,12.564,12.564,0,0,1-3.2-4.318c-1.242-2.621-2.346-7.016-.55-13.074a88.087,88.087,0,0,1,3.516-9.61c1.955-4.679,3.367-8.06,3.481-15.228a.427.427,0,0,1,.453-.439.446.446,0,0,1,.439.453c-.117,7.34-1.632,10.967-3.55,15.558a87.182,87.182,0,0,0-3.484,9.52c-3.276,11.045,3.378,16.382,3.446,16.434a.446.446,0,0,1-.274.8Z"
                      transform="translate(-166.881 -265.247)"
                      fill="#2f1e3a"
                    />
                    <circle
                      id="Ellipse_13"
                      data-name="Ellipse 13"
                      cx="8.799"
                      cy="8.799"
                      r="8.799"
                      transform="translate(89.318)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_201"
                      data-name="Path 201"
                      d="M237.529,335.933s2.706,5.261,13.29,6.883,18.764-2.388,26.97.37,19.868-1.365,22.117-4.84.861-41.32-5.7-47.516-40.3-10.7-47.411-3.095S232.806,331.779,237.529,335.933Z"
                      transform="translate(-168.006 -259.726)"
                      fill="#2f1e3a"
                    />
                    <circle
                      id="Ellipse_14"
                      data-name="Ellipse 14"
                      cx="28.997"
                      cy="28.997"
                      r="28.997"
                      transform="translate(71.668 15.5)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_202"
                      data-name="Path 202"
                      d="M248.731,304.083s2.028,35.159,25.853,36.113,22.624-48.622,13.9-53.133S243.861,284.289,248.731,304.083Z"
                      transform="translate(-171.762 -260.111)"
                      fill="#f08d71"
                    />
                    <path
                      id="Path_203"
                      data-name="Path 203"
                      d="M283.357,322.432s4.8,10.723,3.649,11.468-4.577.7-5.518-.075S281.469,321.265,283.357,322.432Z"
                      transform="translate(-181.782 -271.509)"
                      fill="#d1454b"
                    />
                    <circle
                      id="Ellipse_15"
                      data-name="Ellipse 15"
                      cx="2.236"
                      cy="2.236"
                      r="2.236"
                      transform="translate(90.066 52.426)"
                      fill="#2f1e3a"
                    />
                    <circle
                      id="Ellipse_16"
                      data-name="Ellipse 16"
                      cx="2.236"
                      cy="2.236"
                      r="2.236"
                      transform="translate(109.231 50.918)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_204"
                      data-name="Path 204"
                      d="M264.887,284.817s-8.433,4.328-9.871,12.11-.129,27.591-9.188,26.9C245.828,323.823,227.4,285,264.887,284.817Z"
                      transform="translate(-169.631 -260.055)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_205"
                      data-name="Path 205"
                      d="M281.7,284.038c-.761.573,8.808.322,14.437,6.825s7.322,23.047,8.175,23.334,3.737-20.64-2.41-26.76S286.034,280.781,281.7,284.038Z"
                      transform="translate(-181.948 -259.215)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_206"
                      data-name="Path 206"
                      d="M310.9,327.577s2.069-8.863,6.116-5.822.671,12.331-3.236,13.26S308.843,332.169,310.9,327.577Z"
                      transform="translate(-190.555 -271.133)"
                      fill="#f08d71"
                    />
                    <path
                      id="Path_207"
                      data-name="Path 207"
                      d="M253.841,326.782s-1.825-8.916-5.953-5.987-1.009,12.309,2.871,13.344S255.775,331.429,253.841,326.782Z"
                      transform="translate(-171.046 -270.853)"
                      fill="#f08d71"
                    />
                    <path
                      id="Path_208"
                      data-name="Path 208"
                      d="M296.568,314.078s4.207-2.22,6.8.969c2.28,2.8-2.925.532-4.841.895S294.081,316.147,296.568,314.078Z"
                      transform="translate(-186.181 -268.755)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_209"
                      data-name="Path 209"
                      d="M269.77,315.308s-4.315-2-6.742,1.315c-2.134,2.916,2.948.381,4.88.647S272.36,317.247,269.77,315.308Z"
                      transform="translate(-176.105 -269.177)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_210"
                      data-name="Path 210"
                      d="M289.834,346.691s-2.745-4.148-5.715-3.887-5.247,4.085-5.646,4.246C278.473,347.05,283.108,349.227,289.834,346.691Z"
                      transform="translate(-180.975 -277.746)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_211"
                      data-name="Path 211"
                      d="M267.408,334.936H267.3a7.727,7.727,0,0,1-7.621-7.832,7.759,7.759,0,0,1,7.725-7.622h.107a7.727,7.727,0,0,1-.1,15.453Zm0-14.563a6.862,6.862,0,0,0-6.833,6.742,6.835,6.835,0,0,0,6.741,6.928h.1a6.836,6.836,0,0,0,.092-13.67Z"
                      transform="translate(-175.24 -270.633)"
                      fill="#fffbf4"
                    />
                    <path
                      id="Path_212"
                      data-name="Path 212"
                      d="M295.188,332.713h-.108a7.727,7.727,0,0,1,.1-15.454h.108a7.726,7.726,0,0,1,7.62,7.832A7.758,7.758,0,0,1,295.188,332.713Zm0-14.563a6.836,6.836,0,0,0-.092,13.67h.1a6.861,6.861,0,0,0,6.832-6.741,6.833,6.833,0,0,0-6.741-6.928Z"
                      transform="translate(-183.717 -269.954)"
                      fill="#fffbf4"
                    />
                    <path
                      id="Path_213"
                      data-name="Path 213"
                      d="M259.145,328a.458.458,0,0,1-.154-.027l-2.86-1.051a.446.446,0,1,1,.308-.837l2.859,1.05a.446.446,0,0,1-.154.865Z"
                      transform="translate(-174.069 -272.641)"
                      fill="#fffbf4"
                    />
                    <path
                      id="Path_214"
                      data-name="Path 214"
                      d="M308.4,325.012a.446.446,0,0,1-.133-.871l3.909-1.22a.446.446,0,0,1,.265.851l-3.909,1.22A.44.44,0,0,1,308.4,325.012Z"
                      transform="translate(-189.972 -271.676)"
                      fill="#fffbf4"
                    />
                    <path
                      id="Path_215"
                      data-name="Path 215"
                      d="M273.989,291.183s.008,8.895,5.306,10.5,18.926.9,18.788-1.276c-1.248-19.622-10.471-18.636-18.176-21.147-13.511-4.4-24.559,17.657-22.906,22.87s13.058.345,15.5-.388S273.588,294.965,273.989,291.183Z"
                      transform="translate(-174.373 -258.183)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_216"
                      data-name="Path 216"
                      d="M261.822,337.243s12.019-6.936,17.294-5.965,14.533,1.121,14.953,3.014-11.737,1.039-15.477,1.4S260.5,340.363,261.822,337.243Z"
                      transform="translate(-175.868 -274.204)"
                      fill="#d1454b"
                      opacity="0.21"
                    />
                    <path
                      id="Path_217"
                      data-name="Path 217"
                      d="M271.344,332.334a.446.446,0,0,1-.168-.859c1.209-.492,1.433-.88,1.435-.884a.424.424,0,0,1,.559-.259.462.462,0,0,1,.271.584c-.056.163-.361.747-1.928,1.385A.447.447,0,0,1,271.344,332.334Z"
                      transform="translate(-178.664 -273.935)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_218"
                      data-name="Path 218"
                      d="M297.014,331.243a4.586,4.586,0,0,1-1.919-.573.446.446,0,1,1,.391-.8,3.011,3.011,0,0,0,1.617.476.429.429,0,0,1,.566.242.462.462,0,0,1-.254.591A1.036,1.036,0,0,1,297.014,331.243Z"
                      transform="translate(-185.971 -273.789)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_219"
                      data-name="Path 219"
                      d="M281.072,330.192a.446.446,0,0,1-.044-.889l4.785-.488a.446.446,0,1,1,.09.887l-4.785.488Z"
                      transform="translate(-181.632 -273.48)"
                      fill="#fffbf4"
                    />
                    <path
                      id="Path_220"
                      data-name="Path 220"
                      d="M269.572,267.268a.434.434,0,0,1-.195-.045,7.663,7.663,0,0,1-4.551-5.216c-1.087-4.952,3.337-10.431,3.526-10.662a.446.446,0,0,1,.691.564c-.044.054-4.341,5.381-3.344,9.909a6.819,6.819,0,0,0,4.069,4.6.445.445,0,0,1-.2.846Z"
                      transform="translate(-176.759 -249.791)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_221"
                      data-name="Path 221"
                      d="M286.191,269.2a.446.446,0,0,1-.295-.782c.051-.045,5.165-4.593,4.926-9.224a6.82,6.82,0,0,0-3.257-5.209.446.446,0,1,1,.516-.727,7.662,7.662,0,0,1,3.633,5.893c.259,5.063-5.006,9.74-5.23,9.938A.445.445,0,0,1,286.191,269.2Z"
                      transform="translate(-183.194 -250.401)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_222"
                      data-name="Path 222"
                      d="M323.007,342.047a.446.446,0,0,1-.282-.792c.059-.049,5.925-4.947,4.226-12.7a89.154,89.154,0,0,0-3.018-9.756c-2.107-6.035-4.286-12.276-4.464-18.326a.447.447,0,0,1,.433-.459.466.466,0,0,1,.459.433c.174,5.912,2.33,12.087,4.415,18.059a89.648,89.648,0,0,1,3.047,9.857c1.819,8.3-4.47,13.529-4.534,13.58A.443.443,0,0,1,323.007,342.047Z"
                      transform="translate(-193.485 -264.693)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_223"
                      data-name="Path 223"
                      d="M316.834,302.17l-.044,0a.446.446,0,0,1-.4-.487c.771-7.8-.871-14.137-4.879-18.826-6.452-7.549-16.934-8.243-17.04-8.249a.446.446,0,0,1,.051-.89c.11.006,10.971.724,17.666,8.56,4.171,4.881,5.883,11.44,5.088,19.492A.446.446,0,0,1,316.834,302.17Z"
                      transform="translate(-185.729 -256.667)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_224"
                      data-name="Path 224"
                      d="M280.875,349s4.444-3.118,8.412-.445C289.287,348.554,284.654,350.29,280.875,349Z"
                      transform="translate(-181.708 -279.174)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_225"
                      data-name="Path 225"
                      d="M284.454,353.808c-.439,0-.816,0-1.049-.008a.445.445,0,0,1-.439-.453.424.424,0,0,1,.453-.438,26.9,26.9,0,0,0,4.009-.138.446.446,0,0,1,.323.83A16.1,16.1,0,0,1,284.454,353.808Z"
                      transform="translate(-182.346 -280.782)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_226"
                      data-name="Path 226"
                      d="M252.82,332.95a.445.445,0,0,1-.43-.566,7.278,7.278,0,0,0-2.052-7.217.446.446,0,0,1,.577-.68,8.073,8.073,0,0,1,2.333,8.137A.445.445,0,0,1,252.82,332.95Z"
                      transform="translate(-172.342 -272.128)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_227"
                      data-name="Path 227"
                      d="M313.664,334.436h-.029a.446.446,0,0,1-.417-.474,13.712,13.712,0,0,1,2.92-8.057.446.446,0,0,1,.658.6,13.094,13.094,0,0,0-2.688,7.513A.445.445,0,0,1,313.664,334.436Z"
                      transform="translate(-191.577 -272.549)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_228"
                      data-name="Path 228"
                      d="M292.083,356.209s1.418,15.863-.464,18.372-13.8.627-16.937,0-3.143-26.863-2.509-25.092C277.409,364.125,291.722,351.174,292.083,356.209Z"
                      transform="translate(-178.952 -279.764)"
                      fill="#f08d71"
                    />
                    <path
                      id="Path_229"
                      data-name="Path 229"
                      d="M273.554,361.08a21.544,21.544,0,0,0,18.068-.855s.747,5.91,0,7.272-15.973,2.336-18.971-4.38C272.651,363.117,272.688,360.789,273.554,361.08Z"
                      transform="translate(-179.199 -283.066)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_230"
                      data-name="Path 230"
                      d="M207.485,491.458H316.906s5.589-95.133-8.118-105.479-74.048-5.178-88.254,3.659S207.485,491.458,207.485,491.458Z"
                      transform="translate(-159.306 -289.177)"
                      fill="#f7a655"
                    />
                    <path
                      id="Path_231"
                      data-name="Path 231"
                      d="M270.663,378.777s-4.217,36.569-2.377,41.659-.517,27.656-1.267,30.589-23.484,4.18-29.35,1.833,1.495-65.6,4.779-77.3S270.3,370.752,270.663,378.777Z"
                      transform="translate(-167.852 -285.84)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_232"
                      data-name="Path 232"
                      d="M190.037,307.177s9.146,17.516,15.555,17.679,28.027-26.162,36-26.433,14.816.5,14.847-1.4-11.087-3.975-11.087-3.975,16.078,1.024,12.753-.734-11.293-3.339-11.293-3.339,11.555.466,11.639-.77-12.031-3.057-12.031-3.057,14.742-.47,12.046-2.5-7.772-1.149-21.5.948S192.176,304.025,190.037,307.177Z"
                      transform="translate(-153.989 -259.044)"
                      fill="#f08d71"
                    />
                    <path
                      id="Path_233"
                      data-name="Path 233"
                      d="M190.251,315.393s14.631,8.715,8.261,18.591c-6.784,10.517-6.4,7.392-7.577,11.648s18.16,12.542,19.039,16.184-8.234,48.429-16.771,46.591c-7.431-1.6-48.725-25.034-54.659-48.452C133.944,341.8,171.44,297.394,190.251,315.393Z"
                      transform="translate(-138.157 -268.1)"
                      fill="#f7a655"
                    />
                    <path
                      id="Path_234"
                      data-name="Path 234"
                      d="M263.166,463.858s-16.211,35.711-10.25,40.492,10.762,1.666,16.034,4.77,10.328,1.552,14.563-1.149-6.786-38.229-11.345-41.612S263.987,462,263.166,463.858Z"
                      transform="translate(-172.775 -314.407)"
                      fill="#fff"
                    />
                    <path
                      id="Path_235"
                      data-name="Path 235"
                      d="M317.34,382.394s32.408-6.188,41.217,9.944c6.609,12.1,23.67,65.928,4.259,76s-37.619,4.806-44.665-1.985,5.49-41.786,13.847-44.4S317.34,382.394,317.34,382.394Z"
                      transform="translate(-192.452 -289.475)"
                      fill="#f7a655"
                    />
                    <path
                      id="Path_236"
                      data-name="Path 236"
                      d="M314.975,379.482s.407-2.54-12.145-7.973c-10.018-4.335-18.6,2.982-33.877,3.77s-13.9-5.268-24.71-2.454c-16.678,4.343-3.4,15.276,1.975,16.5S269.6,420.823,314.975,379.482Z"
                      transform="translate(-168.048 -286.093)"
                      fill="#d1454b"
                    />
                    <path
                      id="Path_237"
                      data-name="Path 237"
                      d="M286.776,339.476s-.046,1.035.548,1.128a1.237,1.237,0,0,0,1.409-1.632A14.353,14.353,0,0,1,286.776,339.476Z"
                      transform="translate(-183.509 -276.581)"
                      fill="#fff"
                    />
                    <path
                      id="Path_238"
                      data-name="Path 238"
                      d="M365.208,444.367a.871.871,0,0,1-.127-.008.9.9,0,0,1-.761-.786c-.743-.859-5.731-2.658-9.059-3.858-6.593-2.378-9.549-3.555-10.185-4.841-.539-1.092-.3-2.665.008-4.656.485-3.152,1.088-7.076-.913-10.856-3.456-6.527-3.127-16.209-3.11-16.617a.892.892,0,0,1,1.782.068c0,.1-.325,9.615,2.9,15.715,2.277,4.3,1.6,8.729,1.1,11.961-.238,1.547-.462,3.008-.172,3.594.541.834,5.746,2.712,9.191,3.954,7.763,2.8,10.458,3.927,10.223,5.565A.892.892,0,0,1,365.208,444.367Z"
                      transform="translate(-200.07 -295.779)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_239"
                      data-name="Path 239"
                      d="M338.583,450.956a.888.888,0,0,1-.235-.031c-2.948-.8-7.525-1.641-7.571-1.649a.892.892,0,0,1,.32-1.754c.19.034,4.7.858,7.72,1.682a.892.892,0,0,1-.234,1.752Z"
                      transform="translate(-196.713 -309.7)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_240"
                      data-name="Path 240"
                      d="M350.026,507.154h-.041c-16.507-.748-32.955-7.78-33.844-14.469-.279-2.1-.482-3.153-.607-3.674a.863.863,0,0,1-.476-.909,1.041,1.041,0,0,1,1.006-.994h.01c.9.009,1.2.535,1.834,5.342.691,5.193,15.665,12.175,32.157,12.922a.892.892,0,0,1-.04,1.783Z"
                      transform="translate(-192.136 -321.785)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_241"
                      data-name="Path 241"
                      d="M322.406,479.422a.88.88,0,0,1-.2-.022.891.891,0,0,1-.673-1.067c.039-.172,3.939-17.368,7.586-29.737a.892.892,0,0,1,1.71.505c-3.63,12.313-7.518,29.454-7.556,29.625A.894.894,0,0,1,322.406,479.422Z"
                      transform="translate(-194.109 -309.838)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_242"
                      data-name="Path 242"
                      d="M211.037,423.226a.965.965,0,0,1-.133-.01.892.892,0,0,1-.75-1.014c.092-.615,2.294-15.147,5.316-21.947a80.711,80.711,0,0,0,4.456-13.376.891.891,0,1,1,1.741.386,80.867,80.867,0,0,1-4.567,13.714c-2.923,6.579-5.16,21.339-5.182,21.487A.891.891,0,0,1,211.037,423.226Z"
                      transform="translate(-160.124 -290.987)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_243"
                      data-name="Path 243"
                      d="M188.023,362.086a63.114,63.114,0,0,1-7.963-.517.893.893,0,0,1-.773-1,.9.9,0,0,1,1-.773,53.723,53.723,0,0,0,18.52-.622,17.866,17.866,0,0,0-6.18-.9c-5.118.241-12.605-4.33-12.922-4.526a.892.892,0,0,1,.938-1.517c.072.044,7.341,4.485,11.892,4.261,4.335-.229,9.469,1.7,9.936,2.531a.929.929,0,0,1-.037.959,1.014,1.014,0,0,1-.863.4c-.141.015-.559.115-.964.213A51.331,51.331,0,0,1,188.023,362.086Z"
                      transform="translate(-150.706 -280.586)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_244"
                      data-name="Path 244"
                      d="M216.22,331.119a.893.893,0,0,1-.8-.5c-.022-.046-2.249-4.569-6.04-6.361-4.305-2.037-9.174-9.133-9.379-9.433a.892.892,0,0,1,1.474-1.005c.047.07,4.8,7,8.668,8.825,4.37,2.068,6.783,6.99,6.884,7.2a.891.891,0,0,1-.415,1.19A.874.874,0,0,1,216.22,331.119Z"
                      transform="translate(-156.981 -268.786)"
                      fill="#f07c3d"
                    />
                    <path
                      id="Path_245"
                      data-name="Path 245"
                      d="M282.835,402.314a.445.445,0,0,1-.435-.545c.022-.094,2.148-9.392,2.77-14.116.97-7.364-2.328-9.932-2.362-9.957a.446.446,0,0,1,.529-.718c.154.113,3.761,2.861,2.716,10.792-.627,4.766-2.763,14.1-2.785,14.2A.445.445,0,0,1,282.835,402.314Z"
                      transform="translate(-182.17 -288.152)"
                      fill="#c21823"
                    />
                    <path
                      id="Path_246"
                      data-name="Path 246"
                      d="M244.79,387.826l-.886-.1s.693-6.021,1.054-8.98a20.692,20.692,0,0,1,1.252-5.111.446.446,0,1,1,.821.347,20.256,20.256,0,0,0-1.189,4.872C245.482,381.809,244.79,387.825,244.79,387.826Z"
                      transform="translate(-170.426 -287.074)"
                      fill="#c21823"
                    />
                    <path
                      id="Path_247"
                      data-name="Path 247"
                      d="M263.9,534.5c-5.086,0-7.883-.168-11.754-.4-4.979-.3-11.8-.707-27.73-.954a.892.892,0,1,1,.028-1.784c15.972.247,22.814.658,27.809.957,3.84.23,6.614.4,11.647.4a139.418,139.418,0,0,0,17.42-1.358,140.377,140.377,0,0,1,14.189-1.247c10.362-.229,18.106,0,18.182,0a.892.892,0,0,1,.864.919.911.911,0,0,1-.918.865c-.077,0-7.776-.231-18.089,0a139.015,139.015,0,0,0-14.013,1.234A140.737,140.737,0,0,1,263.9,534.5Z"
                      transform="translate(-164.211 -334.876)"
                      fill="#f07c3d"
                    />
                    <g id="Group_204" data-name="Group 204" transform="translate(136.139 79.467)">
                      <path
                        id="Path_248"
                        data-name="Path 248"
                        d="M345.339,370.336a1.476,1.476,0,0,1-1.308-.846c-1.115-1.876-1.472-2.045-3.815-1.8-2.117.221-2.879-1.307-3.382-2.32a3.04,3.04,0,0,0-.474-.785c-.4-.347-1.437-.006-1.88.213l-.4-.8c.193-.1,1.916-.91,2.862-.085a3.407,3.407,0,0,1,.686,1.061c.485.977.986,1.981,2.49,1.83,2.679-.283,3.381.051,4.675,2.232.344.577.667.518,1.684-.012.773-.4,1.941-1.012,2.732.214l-.749.484c-.308-.477-.612-.406-1.571.094A3.588,3.588,0,0,1,345.339,370.336Z"
                        transform="translate(-334.085 -363.546)"
                        fill="#fff"
                      />
                    </g>
                    <g id="Group_205" data-name="Group 205" transform="translate(58.561 22.689)">
                      <path
                        id="Path_249"
                        data-name="Path 249"
                        d="M222.957,288.23a1.881,1.881,0,0,1-.521-.081l.247-.857c.545.154.728-.093,1.124-1.1.335-.852.839-2.141,2.373-1.633,2.074.685,2.452.577,4.06-1.146,1.454-1.558,3.03-.9,4.073-.468a3.061,3.061,0,0,0,.874.282h0c.525,0,1.088-.939,1.279-1.394l.823.345c-.083.2-.848,1.941-2.1,1.941h0a3.393,3.393,0,0,1-1.213-.351c-1.008-.418-2.048-.85-3.079.252-1.841,1.973-2.588,2.179-4.992,1.384-.639-.212-.844.046-1.263,1.113C224.362,287.218,223.965,288.23,222.957,288.23Z"
                        transform="translate(-222.436 -281.834)"
                        fill="#fff"
                      />
                    </g>
                    <g id="Group_206" data-name="Group 206" transform="translate(28.552 133.109)">
                      <path
                        id="Path_250"
                        data-name="Path 250"
                        d="M189.39,449.373a1.458,1.458,0,0,1-1.479-1.121c-.721-2.061-1.037-2.3-3.384-2.52-2.121-.2-2.563-1.852-2.856-2.943a3.077,3.077,0,0,0-.309-.864c-.317-.414-1.408-.289-1.885-.163l-.229-.862c.209-.054,2.06-.512,2.822.483a3.386,3.386,0,0,1,.462,1.176c.283,1.054.575,2.144,2.078,2.285,2.686.256,3.3.721,4.142,3.114.222.634.55.641,1.651.322.838-.242,2.1-.607,2.636.75l-.83.326c-.208-.527-.518-.52-1.559-.22A4.691,4.691,0,0,1,189.39,449.373Z"
                        transform="translate(-179.249 -440.747)"
                        fill="#fff"
                      />
                    </g>
                    <g id="Group_207" data-name="Group 207" transform="translate(128.612 181.398)">
                      <path
                        id="Path_251"
                        data-name="Path 251"
                        d="M334.727,516.566a1.492,1.492,0,0,1-1.274-.784c-1.2-1.82-1.569-1.97-3.9-1.611-2.107.329-2.938-1.165-3.49-2.152a3.031,3.031,0,0,0-.511-.762c-.41-.324-1.435.065-1.868.3l-.433-.78c.189-.106,1.87-1,2.854-.224a3.407,3.407,0,0,1,.737,1.026c.532.952,1.083,1.932,2.576,1.707,2.666-.412,3.379-.112,4.778,2,.371.561.692.485,1.68-.092.752-.44,1.89-1.1,2.74.081l-.725.519c-.331-.459-.63-.377-1.565.169A3.4,3.4,0,0,1,334.727,516.566Z"
                        transform="translate(-323.252 -510.243)"
                        fill="#fff"
                      />
                    </g>
                    <g id="Group_208" data-name="Group 208" transform="translate(54.019 25.64)">
                      <path
                        id="Path_252"
                        data-name="Path 252"
                        d="M216.176,294.459a2.3,2.3,0,0,1-.277-.018l.116-.885c.56.079.706-.2.946-1.258.2-.891.509-2.238,2.1-1.971,2.153.368,2.513.2,3.841-1.745,1.2-1.759,2.859-1.348,3.956-1.077a3.213,3.213,0,0,0,.906.148c.52-.078.935-1.092,1.054-1.572l.866.216c-.052.209-.547,2.051-1.787,2.237a3.29,3.29,0,0,1-1.253-.164c-1.058-.261-2.153-.533-3.006.715-1.521,2.227-2.23,2.543-4.726,2.121-.666-.113-.827.173-1.081,1.29C217.651,293.288,217.385,294.459,216.176,294.459Z"
                        transform="translate(-215.899 -286.081)"
                        fill="#fbe0d1"
                      />
                    </g>
                    <path
                      id="Path_253"
                      data-name="Path 253"
                      d="M230.436,466.872"
                      transform="translate(-166.316 -315.61)"
                      fill="#2f1e3a"
                    />
                    <path
                      id="Path_254"
                      data-name="Path 254"
                      d="M304.561,456.75s-21.3-7.7-30.545-3.763-16.858,14.1-15.447,14.768,8.961-5.184,8.961-5.184-7.7,7.781-6.271,8.559,8.847-3.375,8.847-3.375-5.179,5.537-2.954,5.96,6.993-4.138,11.966-4.31,11.242,3.759,17.459,4.865S304.561,456.75,304.561,456.75Z"
                      transform="translate(-174.85 -311.031)"
                      fill="#f08d71"
                    />
                    <path
                      id="Path_255"
                      data-name="Path 255"
                      d="M364.638,451.171a.9.9,0,0,1-.166-.016c-.062-.011-6.336-1.2-13.836-2.722a.891.891,0,1,1,.356-1.747c7.487,1.521,13.749,2.705,13.811,2.717a.892.892,0,0,1-.165,1.768Z"
                      transform="translate(-202.778 -309.445)"
                      fill="#f07c3d"
                    />
                  </g>
                </g>
              </g>
              <g id="Group_215" data-name="Group 215" transform="translate(17.159)">
                <path
                  id="Path_256"
                  data-name="Path 256"
                  d="M237.255,344.6a.44.44,0,0,1-.274-.094,12.564,12.564,0,0,1-3.2-4.318c-1.242-2.621-2.346-7.016-.55-13.074a88.087,88.087,0,0,1,3.516-9.61c1.955-4.679,3.367-8.06,3.481-15.228a.427.427,0,0,1,.453-.439.446.446,0,0,1,.439.453c-.117,7.34-1.632,10.967-3.55,15.558a87.182,87.182,0,0,0-3.484,9.52c-3.276,11.045,3.378,16.382,3.446,16.434a.446.446,0,0,1-.274.8Z"
                  transform="translate(-195.455 -265.247)"
                  fill="#2f1e3a"
                />
                <circle
                  id="Ellipse_18"
                  data-name="Ellipse 18"
                  cx="8.799"
                  cy="8.799"
                  r="8.799"
                  transform="translate(60.744)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_257"
                  data-name="Path 257"
                  d="M237.529,335.933s2.706,5.261,13.29,6.883,18.764-2.388,26.97.37,19.868-1.365,22.117-4.84.861-41.32-5.7-47.516-40.3-10.7-47.411-3.095S232.806,331.779,237.529,335.933Z"
                  transform="translate(-196.58 -259.726)"
                  fill="#2f1e3a"
                />
                <circle
                  id="Ellipse_19"
                  data-name="Ellipse 19"
                  cx="28.997"
                  cy="28.997"
                  r="28.997"
                  transform="translate(43.093 15.5)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_258"
                  data-name="Path 258"
                  d="M248.731,304.083s2.028,35.159,25.853,36.113,22.624-48.622,13.9-53.133S243.861,284.289,248.731,304.083Z"
                  transform="translate(-200.336 -260.111)"
                  fill="#f08d71"
                />
                <path
                  id="Path_259"
                  data-name="Path 259"
                  d="M283.357,322.432s4.8,10.723,3.649,11.468-4.577.7-5.518-.075S281.469,321.265,283.357,322.432Z"
                  transform="translate(-210.356 -271.509)"
                  fill="#d1454b"
                />
                <circle
                  id="Ellipse_20"
                  data-name="Ellipse 20"
                  cx="2.236"
                  cy="2.236"
                  r="2.236"
                  transform="translate(61.491 52.426)"
                  fill="#2f1e3a"
                />
                <circle
                  id="Ellipse_21"
                  data-name="Ellipse 21"
                  cx="2.236"
                  cy="2.236"
                  r="2.236"
                  transform="translate(80.657 50.918)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_260"
                  data-name="Path 260"
                  d="M264.887,284.817s-8.433,4.328-9.871,12.11-.129,27.591-9.188,26.9C245.828,323.823,227.4,285,264.887,284.817Z"
                  transform="translate(-198.206 -260.055)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_261"
                  data-name="Path 261"
                  d="M281.7,284.038c-.761.573,8.808.322,14.437,6.825s7.322,23.047,8.175,23.334,3.737-20.64-2.41-26.76S286.034,280.781,281.7,284.038Z"
                  transform="translate(-210.523 -259.215)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_262"
                  data-name="Path 262"
                  d="M310.9,327.577s2.069-8.863,6.116-5.822.671,12.331-3.236,13.26S308.843,332.169,310.9,327.577Z"
                  transform="translate(-219.13 -271.133)"
                  fill="#f08d71"
                />
                <path
                  id="Path_263"
                  data-name="Path 263"
                  d="M253.841,326.782s-1.825-8.916-5.953-5.987-1.009,12.309,2.871,13.344S255.775,331.429,253.841,326.782Z"
                  transform="translate(-199.62 -270.853)"
                  fill="#f08d71"
                />
                <path
                  id="Path_264"
                  data-name="Path 264"
                  d="M296.568,314.078s4.207-2.22,6.8.969c2.28,2.8-2.925.532-4.841.895S294.081,316.147,296.568,314.078Z"
                  transform="translate(-214.755 -268.755)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_265"
                  data-name="Path 265"
                  d="M269.77,315.308s-4.315-2-6.742,1.315c-2.134,2.916,2.948.381,4.88.647S272.36,317.247,269.77,315.308Z"
                  transform="translate(-204.68 -269.177)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_266"
                  data-name="Path 266"
                  d="M289.834,346.691s-2.745-4.148-5.715-3.887-5.247,4.085-5.646,4.246C278.473,347.05,283.108,349.227,289.834,346.691Z"
                  transform="translate(-209.55 -277.746)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_267"
                  data-name="Path 267"
                  d="M267.408,334.936H267.3a7.727,7.727,0,0,1-7.621-7.832,7.759,7.759,0,0,1,7.725-7.622h.107a7.727,7.727,0,0,1-.1,15.453Zm0-14.563a6.862,6.862,0,0,0-6.833,6.742,6.835,6.835,0,0,0,6.741,6.928h.1a6.836,6.836,0,0,0,.092-13.67Z"
                  transform="translate(-203.815 -270.633)"
                  fill="#fffbf4"
                />
                <path
                  id="Path_268"
                  data-name="Path 268"
                  d="M295.188,332.713h-.108a7.727,7.727,0,0,1,.1-15.454h.108a7.726,7.726,0,0,1,7.62,7.832A7.758,7.758,0,0,1,295.188,332.713Zm0-14.563a6.836,6.836,0,0,0-.092,13.67h.1a6.861,6.861,0,0,0,6.832-6.741,6.833,6.833,0,0,0-6.741-6.928Z"
                  transform="translate(-212.292 -269.954)"
                  fill="#fffbf4"
                />
                <path
                  id="Path_269"
                  data-name="Path 269"
                  d="M259.145,328a.458.458,0,0,1-.154-.027l-2.86-1.051a.446.446,0,1,1,.308-.837l2.859,1.05a.446.446,0,0,1-.154.865Z"
                  transform="translate(-202.643 -272.641)"
                  fill="#fffbf4"
                />
                <path
                  id="Path_270"
                  data-name="Path 270"
                  d="M308.4,325.012a.446.446,0,0,1-.133-.871l3.909-1.22a.446.446,0,0,1,.265.851l-3.909,1.22A.44.44,0,0,1,308.4,325.012Z"
                  transform="translate(-218.546 -271.676)"
                  fill="#fffbf4"
                />
                <path
                  id="Path_271"
                  data-name="Path 271"
                  d="M273.989,291.183s.008,8.895,5.306,10.5,18.926.9,18.788-1.276c-1.248-19.622-10.471-18.636-18.176-21.147-13.511-4.4-24.559,17.657-22.906,22.87s13.058.345,15.5-.388S273.588,294.965,273.989,291.183Z"
                  transform="translate(-202.948 -258.183)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_272"
                  data-name="Path 272"
                  d="M261.822,337.243s12.019-6.936,17.294-5.965,14.533,1.121,14.953,3.014-11.737,1.039-15.477,1.4S260.5,340.363,261.822,337.243Z"
                  transform="translate(-204.442 -274.204)"
                  fill="#d1454b"
                  opacity="0.21"
                />
                <path
                  id="Path_273"
                  data-name="Path 273"
                  d="M271.344,332.334a.446.446,0,0,1-.168-.859c1.209-.492,1.433-.88,1.435-.884a.424.424,0,0,1,.559-.259.462.462,0,0,1,.271.584c-.056.163-.361.747-1.928,1.385A.447.447,0,0,1,271.344,332.334Z"
                  transform="translate(-207.238 -273.935)"
                  fill="#d1454b"
                />
                <path
                  id="Path_274"
                  data-name="Path 274"
                  d="M297.014,331.243a4.586,4.586,0,0,1-1.919-.573.446.446,0,1,1,.391-.8,3.011,3.011,0,0,0,1.617.476.429.429,0,0,1,.566.242.462.462,0,0,1-.254.591A1.036,1.036,0,0,1,297.014,331.243Z"
                  transform="translate(-214.545 -273.789)"
                  fill="#d1454b"
                />
                <path
                  id="Path_275"
                  data-name="Path 275"
                  d="M281.072,330.192a.446.446,0,0,1-.044-.889l4.785-.488a.446.446,0,1,1,.09.887l-4.785.488Z"
                  transform="translate(-210.207 -273.48)"
                  fill="#fffbf4"
                />
                <path
                  id="Path_276"
                  data-name="Path 276"
                  d="M269.572,267.268a.434.434,0,0,1-.195-.045,7.663,7.663,0,0,1-4.551-5.216c-1.087-4.952,3.337-10.431,3.526-10.662a.446.446,0,0,1,.691.564c-.044.054-4.341,5.381-3.344,9.909a6.819,6.819,0,0,0,4.069,4.6.445.445,0,0,1-.2.846Z"
                  transform="translate(-205.333 -249.791)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_277"
                  data-name="Path 277"
                  d="M286.191,269.2a.446.446,0,0,1-.295-.782c.051-.045,5.165-4.593,4.926-9.224a6.82,6.82,0,0,0-3.257-5.209.446.446,0,1,1,.516-.727,7.662,7.662,0,0,1,3.633,5.893c.259,5.063-5.006,9.74-5.23,9.938A.445.445,0,0,1,286.191,269.2Z"
                  transform="translate(-211.769 -250.401)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_278"
                  data-name="Path 278"
                  d="M323.007,342.047a.446.446,0,0,1-.282-.792c.059-.049,5.925-4.947,4.226-12.7a89.154,89.154,0,0,0-3.018-9.756c-2.107-6.035-4.286-12.276-4.464-18.326a.447.447,0,0,1,.433-.459.466.466,0,0,1,.459.433c.174,5.912,2.33,12.087,4.415,18.059a89.648,89.648,0,0,1,3.047,9.857c1.819,8.3-4.47,13.529-4.534,13.58A.443.443,0,0,1,323.007,342.047Z"
                  transform="translate(-222.059 -264.693)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_279"
                  data-name="Path 279"
                  d="M316.834,302.17l-.044,0a.446.446,0,0,1-.4-.487c.771-7.8-.871-14.137-4.879-18.826-6.452-7.549-16.934-8.243-17.04-8.249a.446.446,0,0,1,.051-.89c.11.006,10.971.724,17.666,8.56,4.171,4.881,5.883,11.44,5.088,19.492A.446.446,0,0,1,316.834,302.17Z"
                  transform="translate(-214.304 -256.667)"
                  fill="#2f1e3a"
                />
                <path
                  id="Path_280"
                  data-name="Path 280"
                  d="M280.875,349s4.444-3.118,8.412-.445C289.287,348.554,284.654,350.29,280.875,349Z"
                  transform="translate(-210.283 -279.174)"
                  fill="#d1454b"
                />
                <path
                  id="Path_281"
                  data-name="Path 281"
                  d="M284.454,353.808c-.439,0-.816,0-1.049-.008a.445.445,0,0,1-.439-.453.424.424,0,0,1,.453-.438,26.9,26.9,0,0,0,4.009-.138.446.446,0,0,1,.323.83A16.1,16.1,0,0,1,284.454,353.808Z"
                  transform="translate(-210.921 -280.782)"
                  fill="#d1454b"
                />
                <path
                  id="Path_282"
                  data-name="Path 282"
                  d="M252.82,332.95a.445.445,0,0,1-.43-.566,7.278,7.278,0,0,0-2.052-7.217.446.446,0,0,1,.577-.68,8.073,8.073,0,0,1,2.333,8.137A.445.445,0,0,1,252.82,332.95Z"
                  transform="translate(-200.916 -272.128)"
                  fill="#d1454b"
                />
                <path
                  id="Path_283"
                  data-name="Path 283"
                  d="M313.664,334.436h-.029a.446.446,0,0,1-.417-.474,13.712,13.712,0,0,1,2.92-8.057.446.446,0,0,1,.658.6,13.094,13.094,0,0,0-2.688,7.513A.445.445,0,0,1,313.664,334.436Z"
                  transform="translate(-220.152 -272.549)"
                  fill="#d1454b"
                />
                <path
                  id="Path_284"
                  data-name="Path 284"
                  d="M292.083,356.209s1.418,15.863-.464,18.372-13.8.627-16.937,0-3.143-26.863-2.509-25.092C277.409,364.125,291.722,351.174,292.083,356.209Z"
                  transform="translate(-207.526 -279.764)"
                  fill="#f08d71"
                />
                <path
                  id="Path_285"
                  data-name="Path 285"
                  d="M273.554,361.08a21.544,21.544,0,0,0,18.068-.855s.747,5.91,0,7.272-15.973,2.336-18.971-4.38C272.651,363.117,272.688,360.789,273.554,361.08Z"
                  transform="translate(-207.773 -283.066)"
                  fill="#d1454b"
                />
                <path
                  id="Path_286"
                  data-name="Path 286"
                  d="M190.037,307.177s9.146,17.516,15.555,17.679,28.027-26.162,36-26.433,14.816.5,14.847-1.4-11.087-3.975-11.087-3.975,16.078,1.024,12.753-.734-11.293-3.339-11.293-3.339,11.555.466,11.639-.77-12.031-3.057-12.031-3.057,14.742-.47,12.046-2.5-7.772-1.149-21.5.948S192.176,304.025,190.037,307.177Z"
                  transform="translate(-182.563 -259.044)"
                  fill="#f08d71"
                />
                <path
                  id="Path_287"
                  data-name="Path 287"
                  d="M314.975,379.482s.407-2.54-12.145-7.973c-10.018-4.335-18.6,2.982-33.877,3.77s-13.9-5.268-24.71-2.454c-16.678,4.343-3.4,15.276,1.975,16.5S269.6,420.823,314.975,379.482Z"
                  transform="translate(-196.623 -286.093)"
                  fill="#d1454b"
                />
                <path
                  id="Path_288"
                  data-name="Path 288"
                  d="M286.776,339.476s-.046,1.035.548,1.128a1.237,1.237,0,0,0,1.409-1.632A14.353,14.353,0,0,1,286.776,339.476Z"
                  transform="translate(-212.083 -276.581)"
                  fill="#fff"
                />
                <path
                  id="Path_289"
                  data-name="Path 289"
                  d="M211.037,423.226a.965.965,0,0,1-.133-.01.892.892,0,0,1-.75-1.014c.092-.615,2.294-15.147,5.316-21.947a80.711,80.711,0,0,0,4.456-13.376.891.891,0,1,1,1.741.386,80.867,80.867,0,0,1-4.567,13.714c-2.923,6.579-5.16,21.339-5.182,21.487A.891.891,0,0,1,211.037,423.226Z"
                  transform="translate(-188.699 -290.987)"
                  fill="#f07c3d"
                />
                <path
                  id="Path_290"
                  data-name="Path 290"
                  d="M188.023,362.086a63.114,63.114,0,0,1-7.963-.517.893.893,0,0,1-.773-1,.9.9,0,0,1,1-.773,53.723,53.723,0,0,0,18.52-.622,17.866,17.866,0,0,0-6.18-.9c-5.118.241-12.605-4.33-12.922-4.526a.892.892,0,0,1,.938-1.517c.072.044,7.341,4.485,11.892,4.261,4.335-.229,9.469,1.7,9.936,2.531a.929.929,0,0,1-.037.959,1.014,1.014,0,0,1-.863.4c-.141.015-.559.115-.964.213A51.331,51.331,0,0,1,188.023,362.086Z"
                  transform="translate(-179.281 -280.586)"
                  fill="#f07c3d"
                />
                <path
                  id="Path_291"
                  data-name="Path 291"
                  d="M216.22,331.119a.893.893,0,0,1-.8-.5c-.022-.046-2.249-4.569-6.04-6.361-4.305-2.037-9.174-9.133-9.379-9.433a.892.892,0,0,1,1.474-1.005c.047.07,4.8,7,8.668,8.825,4.37,2.068,6.783,6.99,6.884,7.2a.891.891,0,0,1-.415,1.19A.874.874,0,0,1,216.22,331.119Z"
                  transform="translate(-185.555 -268.786)"
                  fill="#f07c3d"
                />
                <path
                  id="Path_292"
                  data-name="Path 292"
                  d="M282.835,402.314a.445.445,0,0,1-.435-.545c.022-.094,2.148-9.392,2.77-14.116.97-7.364-2.328-9.932-2.362-9.957a.446.446,0,0,1,.529-.718c.154.113,3.761,2.861,2.716,10.792-.627,4.766-2.763,14.1-2.785,14.2A.445.445,0,0,1,282.835,402.314Z"
                  transform="translate(-210.745 -288.152)"
                  fill="#c21823"
                />
                <path
                  id="Path_293"
                  data-name="Path 293"
                  d="M244.79,387.826l-.886-.1s.693-6.021,1.054-8.98a20.692,20.692,0,0,1,1.252-5.111.446.446,0,1,1,.821.347,20.256,20.256,0,0,0-1.189,4.872C245.482,381.809,244.79,387.825,244.79,387.826Z"
                  transform="translate(-199.001 -287.074)"
                  fill="#c21823"
                />
                <g id="Group_212" data-name="Group 212" transform="translate(107.565 79.467)">
                  <path
                    id="Path_294"
                    data-name="Path 294"
                    d="M345.339,370.336a1.476,1.476,0,0,1-1.308-.846c-1.115-1.876-1.472-2.045-3.815-1.8-2.117.221-2.879-1.307-3.382-2.32a3.04,3.04,0,0,0-.474-.785c-.4-.347-1.437-.006-1.88.213l-.4-.8c.193-.1,1.916-.91,2.862-.085a3.407,3.407,0,0,1,.686,1.061c.485.977.986,1.981,2.49,1.83,2.679-.283,3.381.051,4.675,2.232.344.577.667.518,1.684-.012.773-.4,1.941-1.012,2.732.214l-.749.484c-.308-.477-.612-.406-1.571.094A3.588,3.588,0,0,1,345.339,370.336Z"
                    transform="translate(-334.085 -363.546)"
                    fill="#fff"
                  />
                </g>
                <g id="Group_213" data-name="Group 213" transform="translate(29.986 22.689)">
                  <path
                    id="Path_295"
                    data-name="Path 295"
                    d="M222.957,288.23a1.881,1.881,0,0,1-.521-.081l.247-.857c.545.154.728-.093,1.124-1.1.335-.852.839-2.141,2.373-1.633,2.074.685,2.452.577,4.06-1.146,1.454-1.558,3.03-.9,4.073-.468a3.061,3.061,0,0,0,.874.282h0c.525,0,1.088-.939,1.279-1.394l.823.345c-.083.2-.848,1.941-2.1,1.941h0a3.393,3.393,0,0,1-1.213-.351c-1.008-.418-2.048-.85-3.079.252-1.841,1.973-2.588,2.179-4.992,1.384-.639-.212-.844.046-1.263,1.113C224.362,287.218,223.965,288.23,222.957,288.23Z"
                    transform="translate(-222.436 -281.834)"
                    fill="#fff"
                  />
                </g>
                <g id="Group_214" data-name="Group 214" transform="translate(25.444 25.64)">
                  <path
                    id="Path_296"
                    data-name="Path 296"
                    d="M216.176,294.459a2.3,2.3,0,0,1-.277-.018l.116-.885c.56.079.706-.2.946-1.258.2-.891.509-2.238,2.1-1.971,2.153.368,2.513.2,3.841-1.745,1.2-1.759,2.859-1.348,3.956-1.077a3.213,3.213,0,0,0,.906.148c.52-.078.935-1.092,1.054-1.572l.866.216c-.052.209-.547,2.051-1.787,2.237a3.29,3.29,0,0,1-1.253-.164c-1.058-.261-2.153-.533-3.006.715-1.521,2.227-2.23,2.543-4.726,2.121-.666-.113-.827.173-1.081,1.29C217.651,293.288,217.385,294.459,216.176,294.459Z"
                    transform="translate(-215.899 -286.081)"
                    fill="#fbe0d1"
                  />
                </g>
              </g>
            </g>
          </g>
          <text
            id="Shortness_of_Breath"
            data-name="Shortness of Breath"
            transform="translate(511.978 247.325)"
            fill="none"
            fontSize="17"
            fontFamily="OpenSans-Extrabold, Open Sans"
            fontWeight="800"
          >
            <tspan x="0" y="0">
              {t(lo.sym_ShortnessOfBreath)}
            </tspan>
          </text>
          <text
            id="Fever"
            transform="translate(492.072 454.182)"
            fill="none"
            fontSize="17"
            fontFamily="OpenSans-Extrabold, Open Sans"
            fontWeight="800"
          >
            <tspan x="0" y="0">
              {t(lo.sym_Fever)}
            </tspan>
          </text>
          <text
            id="Dry_Cough"
            data-name="Dry Cough"
            transform="translate(667.77 454.182)"
            fill="none"
            fontSize="17"
            fontFamily="OpenSans-Extrabold, Open Sans"
            fontWeight="800"
          >
            <tspan x="0" y="0">
              {t(lo.sym_DryCough)}
            </tspan>
          </text>
          <text
            id="Headache"
            transform="translate(698.928 331.876)"
            fill="none"
            fontSize="15"
            fontFamily="OpenSans-Semibold, Open Sans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              {t(lo.sym_Headache)}
            </tspan>
          </text>
          <text
            id="Aches_Pain"
            data-name="Aches &amp; Pain"
            transform="translate(396 295.525)"
            fill="none"
            fontSize="15"
            fontFamily="OpenSans-Semibold, Open Sans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              {t(lo.sym_AcheNPain)}
            </tspan>
          </text>
          <text
            id="Diarrohea"
            transform="translate(737 275.559)"
            fill="none"
            fontSize="13"
            fontFamily="OpenSans-Light, Open Sans"
            fontWeight="300"
          >
            <tspan x="0" y="0">
              {t(lo.sym_Diarrohea)}
            </tspan>
          </text>
          <text
            id="Fatigue"
            transform="translate(406.386 372.555)"
            fill="none"
            fontSize="15"
            fontFamily="OpenSans-Semibold, Open Sans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              {t(lo.sym_Fatigue)}
            </tspan>
          </text>
          <text
            id="Sore_Throat"
            data-name="Sore Throat"
            transform="translate(555.254 518.826)"
            fill="none"
            fontSize="15"
            fontFamily="OpenSans-Semibold, Open Sans"
            fontWeight="600"
          >
            <tspan x="0" y="0">
              {t(lo.sym_SoreThroat)}
            </tspan>
          </text>
          <text
            id="Runny_Nose"
            data-name="Runny Nose"
            transform="translate(422.193 496.054)"
            fill="none"
            fontSize="13"
            fontFamily="OpenSans-Light, Open Sans"
            fontWeight="300"
          >
            <tspan x="0" y="0">
              {t(lo.sym_RunnyNose)}
            </tspan>
          </text>
        </g>
      </svg>

      <div className="left left-1">{t(lo.sym_ShortnessOfBreath)}</div>
      <div className="left left-2">{t(lo.sym_AcheNPain)}</div>
      <div className="left-3 left">{t(lo.sym_Fatigue)}</div>
      <div className="left-4 left">{t(lo.sym_Fever)}</div>
      <div className="left-5 left">{t(lo.sym_RunnyNose)}</div>
      <div className="left left-6">{t(lo.sym_SoreThroat)}</div>
      <div className="right right-1">{t(lo.sym_DryCough)}</div>
      <div className="right right-2">{t(lo.sym_Headache)}</div>
      <div className="right-3 right">{t(lo.sym_Diarrohea)}</div>
    </div>
  );
};

export default SymtomSVG;
