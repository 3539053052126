import React, { FC } from 'react';

const HospitalCross: FC<{}> = () => (
  <span className="mr-3">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
      <g id="Group_583" data-name="Group 583" transform="translate(-764 -193)">
        <g id="Group_581" data-name="Group 581" transform="translate(768.8 197.8)">
          <path
            id="Union_1"
            data-name="Union 1"
            d="M7.143,14.4a2,2,0,0,1-2-2V9.257H2a2,2,0,0,1-2-2V7.142a2,2,0,0,1,2-2H5.143V2a2,2,0,0,1,2-2h.113a2,2,0,0,1,2,2V5.143H12.4a2,2,0,0,1,2,2v.114a2,2,0,0,1-2,2H9.257V12.4a2,2,0,0,1-2,2Z"
            fill="#fff"
          />
        </g>
        <g
          id="Rectangle_789"
          data-name="Rectangle 789"
          transform="translate(764 193)"
          fill="none"
          stroke="#fff"
          strokeLinecap="square"
          strokeWidth="2"
        >
          <rect width="24" height="24" rx="4" stroke="none" />
          <rect x="1" y="1" width="22" height="22" rx="3" fill="none" />
        </g>
      </g>
    </svg>
  </span>
);

export default HospitalCross;
